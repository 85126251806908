/*
 * Welcome to your app's main JavaScript file!
 *
 * We recommend including the built version of this JavaScript file
 * (and its CSS file) in your base layout (base.html.twig).
 */

// any CSS you import will output into a single css file (app.css in this case)
import './styles/style.scss';
import '../node_modules/simplelightbox/src/simple-lightbox.scss';
require('bootstrap');


const $ = require('jquery');
global.$ = global.jQuery = $;

require('simplelightbox');

new SimpleLightbox('.gallery a');